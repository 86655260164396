import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Fragment, Suspense } from 'react';
import ReactDOM from 'react-dom';
import SplashScreen from 'components/SplashScreen'
import { unregister } from './registerServiceWorker';
import IncompatableBrowserScreen from 'components/IncompatableBrowserScreen'
// import "core-js/stable";
// import "regenerator-runtime/runtime";

const rootElement = document.getElementById('root');

let render = () => {
    const App = React.lazy(() => import('./App'));

    ReactDOM.render(
        <Fragment>
            <IncompatableBrowserScreen render={() => (
                <Suspense fallback={<SplashScreen />}>
                    <App />
                </Suspense>
            )} />
        </Fragment>, rootElement);
}

// HMR - Issue with babel
// if (module.hot) {
//     // Support hot reloading of components
//     const renderApp = render;

//     render = () => {
//         try {
//             renderApp();
//         }
//         catch (error) {
//             console.error(error);
//             throw new Error(error);
//         }
//     };

//     // Whenever the App component file or one of its dependencies
//     // is changed, re-import the updated component and re-render it
//     module.hot.accept("./App", () => {
//         setTimeout(render);
//     });
// }

render();
unregister();